<template>
  <VRow>
    <VCol cols="12">
      <KioskGuardUserInteractionRequired
        @user-inactif="$router.push({ name: 'dial.index' })"
      />
      <VCard flat class="cardPrimary">
        <VRow align="center">
          <VCol cols="12">
            <h1 class="display-2 primary--text">Whoops</h1>
            <p>Something wrong happened !</p>
            <VBtn :to="{ name: 'dial.index' }" outlined color="primary">
              Get me out of here !
            </VBtn>
          </VCol>
        </VRow>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
import KioskGuards from '@/guards'
export default {
  name: 'Error404',
  components: {
    ...KioskGuards
  }
}
</script>
